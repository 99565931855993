<template>
  <modal-content class="archive-rent">
    <template #body>
      <div class="container py-3">
        <h2 class="p-3">Archive Rent</h2>
        <div>
          <PropertySummary
            class="p-2 mb-4"
            :property="property"
            address
          ></PropertySummary>
        </div>
        <archive-rent-form-content
          ref="archiveRentForm"
          :propertyId="property.id"
          :content="archiveRentContent"
          :isLoading.sync="isLoading"
          @validated="archiveRent"
        ></archive-rent-form-content>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end py-2">
        <fd-button @click="closeModal" class="mx-1">Cancel</fd-button>
        <fd-button
          class="main mx-1"
          @click="triggerSubmit"
          :disabled="isLoading"
          loadingEnabled
          :isLoading="isLoading"
          >Archive</fd-button
        >
      </div>
    </template>
  </modal-content>
</template>

<script>
import { RentTAModel } from "@/models";
import { createArchiveRent } from "@/modules/Rent/api/archiveRent";

export default {
  components: {
    PropertySummary: () =>
      import("@/components/GlobalComponents/Shared/Property/PropertySummary"),
    ArchiveRentFormContent: () =>
      import("@/modules/Rent/components/ArchiveRent/ArchiveRentFormContent")
  },
  mixins: [],
  props: {
    archiveRentContent: {
      type: Object,
      default: () => ({})
    },
    property: {
      type: Object
    }
  },
  data: function () {
    return {
      isLoading: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    triggerSubmit() {
      this.$refs.archiveRentForm.submit();
    },
    async archiveRent(archiveRentContent) {
      try {
        this.$store.commit("setIsLoading", true);
        let payload = RentTAModel.toCreateArchiveRent(archiveRentContent);

        await createArchiveRent(payload);
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Archive For Rent",
          text: "Property has been archived for rent purpose."
        });
        this.closeModal();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Archive For Rent",
          text:
            "Failed to archive property for rent purpose. Please try again later."
        });
      }
    },
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss"></style>
